import { Card, Col, Flex, Row } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Server } from "../../server/server";
import './BalancePage.css';

class BalancePage extends React.Component<WithTranslation, BalancePageState> {
    /** 充值成功后重定向页面（来源页面）URL */
    private redirect: string = ''
    constructor(props: any) {
        super(props)
        const {search} = window.location
        // let { redirect } = qs.parse(search.replace(/^\?/, ''))
        const searchParams = new URLSearchParams(search)
        let redirect = searchParams.get('redirect')
        if (redirect == undefined || redirect == null) redirect = ""
        this.redirect = redirect.toString()
        console.log("redirect:" + redirect)
        // 初始化
        this.state = {
            totalAmount: 0,
            totalAmountStr: '',
            rechargeGoods: [],
            selectedIndex: 0,
            alert: ''
        }
    }

    componentDidMount(): void {
        Server.user.addEventListener('user-login', this.onUserLogin);
        Server.user.addEventListener('user-logout', this.onUserLogout);
        this.loadInfo()
    }

    componentWillUnmount(): void {
        Server.user.removeEventListener('user-login', this.onUserLogin);
        Server.user.removeEventListener('user-logout', this.onUserLogout);
    }

    render(): React.ReactNode {
        const { t } = this.props
        let isLogin = Server.user.isLogin()
        if (!isLogin) {
            return <></>
        }
        const totalAmountStr = this.state.totalAmountStr
        const rechargeGoods = this.state.rechargeGoods
        let selectedIndex = this.state.selectedIndex
        const selectedGoods = rechargeGoods[selectedIndex]
        let priceCnyStr = '', priceJpyStr = ''
        if (selectedGoods) {
            const priceCny = selectedGoods.cny
            priceCnyStr = Number(priceCny).toLocaleString()
            const priceJpy = selectedGoods.jpy
            priceJpyStr = Number(priceJpy).toLocaleString()
        }
        return (
            <div className="main">
                <Flex gap="small" align="center" justify="space-between" vertical={false} style={{width: "100%", marginBottom:"30px"}}>
                    <Flex align="start" vertical>
                        <span>{t('pages.BalancePage.376888-0')}</span>
                        <span style={{fontSize:"120%", fontWeight: "bolder"}}>{totalAmountStr}{t('pages.BalancePage.376888-1')}</span>
                    </Flex>
                    <div>
                        <NavLink to={'/trans'}>{t('pages.BalancePage.376888-2')}</NavLink>
                    </div>
                </Flex>

                <Flex vertical gap="small">              
                    <span>{t('pages.BalancePage.376888-3')}</span>
                    <Row gutter={8}>
                        {
                            rechargeGoods.map((goods, index) => {
                                const value = goods.value
                                const priceCny = Number(goods.cny).toLocaleString()
                                const priceJpy = Number(goods.jpy).toLocaleString()
                                return (
                                    <Col key={index} span={6}>
                                        <Card hoverable size="default" bordered={false} className={'' + (selectedIndex == index ? 'selected' : '')} onClick={()=>this.setState({selectedIndex: index})}>
                                            <Flex gap="small" align="center" vertical justify="center">
                                                <div className="recharge-value">
                                                    <span className="text">{value}{t('pages.BalancePage.376888-1')}</span>
                                                </div>
                                                <div className="recharge-amount">
                                                    {/* <span className="cny">{priceCny}{t('pages.BalancePage.376888-4')}</span>
                                                    <span className="or">/</span> */}
                                                    <span className="jpy">{priceJpy}{t('pages.BalancePage.376888-5')}</span>
                                                </div>
                                            </Flex>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    <Flex gap="small" align="start" vertical>
                        {/* <button className="base-button" style={{width:"100%"}} type="button" onClick={this.rechargeCny}>{t('pages.BalancePage.376888-6', {amount: priceCnyStr})}</button> */}
                        <button className="base-button" style={{width:"100%"}} type="button" onClick={this.rechargeJpy}>{t('pages.BalancePage.376888-7', {amount: priceJpyStr})}</button>
                    </Flex>
                    <div className="agreement">
                        <span>{t('pages.BalancePage.376888-8')}<a target="_blank" href="/agreement" >{t('pages.BalancePage.376888-9')}</a>{t('pages.BalancePage.376888-10')}</span>
                    </div>
                </Flex>
            </div>
        )
    }

    // 用户登录回调函数
    onUserLogin = () => {
        console.log('用户登录事件')
        // this.forceUpdate()
        this.loadInfo()
    }

    // 用户退出登录回调函数
    onUserLogout = () => {
        console.log('用户退出登录事件')
        this.forceUpdate()
    }

    rechargeJpy = () => {
        const goods = this.state.rechargeGoods[this.state.selectedIndex]
        const id = goods.id
        const redirect = this.redirect || ''
        window.location.href ='/recharge?good='+id+'&currency=jpy&redirect=' + encodeURIComponent(redirect)
    }

    rechargeCny = () => {
        const goods = this.state.rechargeGoods[this.state.selectedIndex]
        const id = goods.id
        const redirect = this.redirect || ''
        window.location.href ='/recharge?good='+id+'&currency=cny&redirect=' + encodeURIComponent(redirect)
    }

    loadInfo = () => {
        this.loadUserBalance()
        this.loadRechargeGoods()
    }

    loadUserBalance = () => {
        Server.account.balance().then(res => {
            if (!res.success) {
                return
            }

            let amount : number = 0
            const account = res.data
            if (account) {
                amount = Number(Number(account.amount).toFixed(0))
            }
            let amountStr = amount.toLocaleString()

            this.setState({
                totalAmount: amount,
                totalAmountStr: amountStr,
            })
        })
    }

    loadRechargeGoods = () => {
        Server.goods.getAllRechargeGoods().then(res => {
            if (!res.success) {
                return
            }

            const goodsList = res.data || []
            this.setState({
                rechargeGoods: goodsList,
                selectedIndex: 0,
            })
        })
    }
}

export default withTranslation()(BalancePage)

interface BalancePageState {
    /** 总资产（剩余梧桐果数量） */
    totalAmount: number,
    /** 总资产显示用（剩余梧桐果数量） */
    totalAmountStr: string,
    /** 充值商品列表 */
    rechargeGoods: RechargeGoods[];
    /** 选择的充值项索引 */
    selectedIndex: number;
    /** 警告消息 */
    alert: string;
}

interface RechargeGoods {
    id: string,
    name: string,
    nameZh: string
    /** 梧桐果数量 */
    value: number
    /** 人民币 */
    cny: number
    /** 人民币（含税） */
    cnyInclTax: number
    /** 日元 */
    jpy: number
    /** 日元（含税） */
    jpyInclTax: number
    // /** 税额 */
    // tax: number
    // /** 税额显示用 */
    // taxStr: string
}