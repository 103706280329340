import React from 'react';
import 'react-quill/dist/quill.snow.css';
import Api from '../api/Api';
import SharedQuillEditor from '../elements/SharedQuillEditor';
import './ActivityPage.css';

interface ActivityPageState {
  caseParseResult: any
  talentParseResult: any
  matchScoreResult: any
}

class ActivityPage extends React.Component<{}, ActivityPageState> {
  quillRef: any;
  quillRefTalent: any;
  constructor(props: {}) {
    super(props);
    this.state = {
      caseParseResult: '',
      talentParseResult: '',
      matchScoreResult: ''
    };
  }

  parseCase() {
    // Parser
    // let html = this.quillRef.root.innerHTML;
    let text = this.quillRef.getText()
    let textOri = text.trim().replace(/[\r\n]/g, '')
    if(textOri.length == 0) {
        return
    }
    // console.log("html:" + html)
    console.log("text:" + text)
    Api.post('/api/match/test/parseContent', { text }).then(res => {
      console.log("result:", res)
      this.setState({
        caseParseResult: res.data
      })
    }).catch(err => {
      console.log(err)
    })
  }

  parseTalent() {
    // Parser
    let html = this.quillRefTalent.root.innerHTML;
    let text = this.quillRefTalent.getText()
    let textOri = text.trim().replace(/[\r\n]/g, '')
    if(textOri.length == 0) {
        return
    }
    // console.log("html:" + html)
    console.log("text:" + text)
    Api.post('/api/match/test/parseContent', { text }).then(res => {
      console.log("result:", res)
      this.setState({
        talentParseResult: res.data
      })
    }).catch(err => {
      console.log(err)
    })
  }

  match() {
    const caseParseResult = this.state.caseParseResult
    const talentParseResult = this.state.talentParseResult
    if (caseParseResult && talentParseResult) {
      Api.post('/api/match/test/getScore', { caseParseResult, talentParseResult }).then(res => {
        console.log("result:", res)
        this.setState({
          matchScoreResult: res.data
        })
      }).catch(err => {
        console.log(err)
      })
    }
  }

  render() {
    const caseParseResult = this.state.caseParseResult
    const talentParseResult = this.state.talentParseResult
    const matchScoreResult = this.state.matchScoreResult
    return (
      <div className="activity-page">
        <div className='test-main'>
          <div className='case'>
            <SharedQuillEditor placeholder='请输入...' getRef={(ref: any) => this.quillRef = ref} />
            <button onClick={()=>this.parseCase()}>解析</button>
            {/* <div className='activity-page-filters'>
              隐藏价格的原文：<pre>{privateContent && parse(privateContent)}</pre>
            </div> */}
            {/* <div className='row-item'>
              <div className='title'>预处理后</div>
              <div className='grow'><pre>{content}</pre></div>
            </div> */}
            <div className='row-item'>
              <div className='title'>性别</div>
              <div className='grow'>{caseParseResult.gender}</div>
            </div>
            <div className='row-item'>
              <div className='title'>年龄</div>
              <div className='grow'>{caseParseResult.age}</div>
            </div>
            <div className='row-item'>
              <div className='title'>年龄2</div>
              <div className='grow'>{caseParseResult.ageNoSuffix}</div>
            </div>
            <div className='row-item'>
              <div className='title'>国・地域</div>
              <div className='grow'>{caseParseResult.country}</div>
            </div>
            <div className='row-item'>
              <div className='title'>日语水平</div>
              <div className='grow'>{caseParseResult.japanese_level}</div>
            </div>
            <div className='row-item'>
              <div className='title'>英语水平</div>
              <div className='grow'>{caseParseResult.english_level}</div>
            </div>
            <div className='row-item'>
              <div className='title'>经验年数</div>
              <div className='grow'>{caseParseResult.work_exp_years}</div>
            </div>
            <div className='row-item'>
              <div className='title'>在日年数</div>
              <div className='grow'>{caseParseResult.in_japan_years}</div>
            </div>
            <div className='row-item'>
              <div className='title'>必备技能</div>
              <div className='grow'>{caseParseResult.must_skill}</div>
            </div>
            <div className='row-item'>
              <div className='title'>尚可技能</div>
              <div className='grow'>{caseParseResult.can_skill}</div>
            </div>
            <div className='row-item'>
              <div className='title'>作业工程阶段数组</div>
              <div className='grow'>{caseParseResult.work_process && caseParseResult.work_process.join(',')}</div>
            </div>
            <div className='row-item'>
              <div className='title'>工作方式</div>
              <div className='grow'>{caseParseResult.work_style}</div>
            </div>
            <div className='row-item'>
              <div className='title'>胜任岗位数组</div>
              <div className='grow'>{caseParseResult.work_role && caseParseResult.work_role.join(',')}</div>
            </div>
            <div className='row-item'>
              <div className='title'>工作地点</div>
              <div className='grow'>{caseParseResult.work_place}</div>
            </div>
            <div className='row-item'>
              <div className='title'>稼动日期</div>
              <div className='grow'>{caseParseResult.move_time}</div>
            </div>
            <div className='row-item'>
              <div className='title'>IT经验</div>
              <div className='grow'>{caseParseResult.it_exp}</div>
            </div>
            <div className='row-item'>
              <div className='title'>案件所属</div>
              <div className='grow'>{caseParseResult.case_belong}</div>
            </div>
            <div className='row-item'>
              <div className='title'>要员所属</div>
              <div className='grow'>{caseParseResult.talent_belong}</div>
            </div>
            <div className='row-item'>
              <div className='title'>案件概要</div>
              <div className='grow'>{caseParseResult.case_summary}</div>
            </div>
            <div className='row-item'>
              <div className='title'>单价</div>
              <div className='grow'>{caseParseResult.price}</div>
            </div>
            <div className='row-item'>
              <div className='title'>氏名</div>
              <div className='grow'>{caseParseResult.name}</div>
            </div>
            <div className='row-item'>
              <div className='title'>要员概要</div>
              <div className='grow'>{caseParseResult.talent_summary}</div>
            </div>
            <div className='row-item'>
              <div className='title'>案件主题</div>
              <div className='grow'>{caseParseResult.case_topic}</div>
            </div>
            <div className='row-item'>
              <div className='title'>要员主题</div>
              <div className='grow'>{caseParseResult.talent_topic}</div>
            </div>
            <div className='row-item'>
              <div className='title'>技术分野</div>
              <div className='grow'>{caseParseResult.tech_category}</div>
            </div>
            <div className='row-item'>
              <div className='title'>开发语言</div>
              <div className='grow'>{caseParseResult.dev_lang && caseParseResult.dev_lang.join(",")}</div>
            </div>
            <div className='row-item'>
              <div className='title'>IDE</div>
              <div className='grow'>{caseParseResult.ide && caseParseResult.ide.join(',')}</div>
            </div>
            <div className='row-item'>
              <div className='title'>数据库</div>
              <div className='grow'>{caseParseResult.dbms && caseParseResult.dbms.join(',')}</div>
            </div>
            <div className='row-item'>
              <div className='title'>OS</div>
              <div className='grow'>{caseParseResult.os && caseParseResult.os.join(',')}</div>
            </div>
            <div className='row-item'>
              <div className='title'>系统构筑产品</div>
              <div className='grow'>{caseParseResult.platform_products && caseParseResult.platform_products.join(',')}</div>
            </div>
            <div className='row-item'>
              <div className='title'>系统构筑基盘</div>
              <div className='grow'>{caseParseResult.sys_infra && caseParseResult.sys_infra.join(',')}</div>
            </div>
            <div className='row-item'>
              <div className='title'>开发框架</div>
              <div className='grow'>{caseParseResult.framework && caseParseResult.framework.join(',')}</div>
            </div>
            <div className='row-item'>
              <div className='title'>开发工具</div>
              <div className='grow'>{caseParseResult.dev_tools && caseParseResult.dev_tools.join(',')}</div>
            </div>
          </div>
          <div className='talent'>
            <SharedQuillEditor placeholder='请输入...' getRef={(ref: any) => this.quillRefTalent = ref} />
            <button onClick={()=>this.parseTalent()}>解析</button>
            {/* <div className='activity-page-filters'>
              隐藏价格的原文：<pre>{privateContent && parse(privateContent)}</pre>
            </div> */}
            {/* <div className='row-item'>
              <div className='title'>预处理后</div>
              <div className='grow'><pre>{content}</pre></div>
            </div> */}
            <div className='row-item'>
              <div className='title'>性别</div>
              <div className='grow'>{talentParseResult.gender}</div>
            </div>
            <div className='row-item'>
              <div className='title'>年龄</div>
              <div className='grow'>{talentParseResult.age}</div>
            </div>
            <div className='row-item'>
              <div className='title'>年龄2</div>
              <div className='grow'>{talentParseResult.ageNoSuffix}</div>
            </div>
            <div className='row-item'>
              <div className='title'>国・地域</div>
              <div className='grow'>{talentParseResult.country}</div>
            </div>
            <div className='row-item'>
              <div className='title'>日语水平</div>
              <div className='grow'>{talentParseResult.japanese_level}</div>
            </div>
            <div className='row-item'>
              <div className='title'>英语水平</div>
              <div className='grow'>{talentParseResult.english_level}</div>
            </div>
            <div className='row-item'>
              <div className='title'>经验年数</div>
              <div className='grow'>{talentParseResult.work_exp_years}</div>
            </div>
            <div className='row-item'>
              <div className='title'>在日年数</div>
              <div className='grow'>{talentParseResult.in_japan_years}</div>
            </div>
            <div className='row-item'>
              <div className='title'>必备技能</div>
              <div className='grow'>{talentParseResult.must_skill}</div>
            </div>
            <div className='row-item'>
              <div className='title'>尚可技能</div>
              <div className='grow'>{talentParseResult.can_skill}</div>
            </div>
            <div className='row-item'>
              <div className='title'>作业工程阶段数组</div>
              <div className='grow'>{talentParseResult.work_process && talentParseResult.work_process.join(',')}</div>
            </div>
            <div className='row-item'>
              <div className='title'>工作方式</div>
              <div className='grow'>{talentParseResult.work_style}</div>
            </div>
            <div className='row-item'>
              <div className='title'>胜任岗位数组</div>
              <div className='grow'>{talentParseResult.work_role && talentParseResult.work_role.join(',')}</div>
            </div>
            <div className='row-item'>
              <div className='title'>工作地点</div>
              <div className='grow'>{talentParseResult.work_place}</div>
            </div>
            <div className='row-item'>
              <div className='title'>稼动日期</div>
              <div className='grow'>{talentParseResult.move_time}</div>
            </div>
            <div className='row-item'>
              <div className='title'>IT经验</div>
              <div className='grow'>{talentParseResult.it_exp}</div>
            </div>
            <div className='row-item'>
              <div className='title'>案件所属</div>
              <div className='grow'>{talentParseResult.case_belong}</div>
            </div>
            <div className='row-item'>
              <div className='title'>要员所属</div>
              <div className='grow'>{talentParseResult.talent_belong}</div>
            </div>
            <div className='row-item'>
              <div className='title'>案件概要</div>
              <div className='grow'>{talentParseResult.case_summary}</div>
            </div>
            <div className='row-item'>
              <div className='title'>单价</div>
              <div className='grow'>{talentParseResult.price}</div>
            </div>
            <div className='row-item'>
              <div className='title'>氏名</div>
              <div className='grow'>{talentParseResult.name}</div>
            </div>
            <div className='row-item'>
              <div className='title'>要员概要</div>
              <div className='grow'>{talentParseResult.talent_summary}</div>
            </div>
            <div className='row-item'>
              <div className='title'>案件主题</div>
              <div className='grow'>{talentParseResult.case_topic}</div>
            </div>
            <div className='row-item'>
              <div className='title'>要员主题</div>
              <div className='grow'>{talentParseResult.talent_topic}</div>
            </div>
            <div className='row-item'>
              <div className='title'>技术分野</div>
              <div className='grow'>{talentParseResult.tech_category}</div>
            </div>
            <div className='row-item'>
              <div className='title'>开发语言</div>
              <div className='grow'>{talentParseResult.dev_lang && talentParseResult.dev_lang.join(",")}</div>
            </div>
            <div className='row-item'>
              <div className='title'>IDE</div>
              <div className='grow'>{talentParseResult.ide && talentParseResult.ide.join(',')}</div>
            </div>
            <div className='row-item'>
              <div className='title'>数据库</div>
              <div className='grow'>{talentParseResult.dbms && talentParseResult.dbms.join(',')}</div>
            </div>
            <div className='row-item'>
              <div className='title'>OS</div>
              <div className='grow'>{talentParseResult.os && talentParseResult.os.join(',')}</div>
            </div>
            <div className='row-item'>
              <div className='title'>系统构筑产品</div>
              <div className='grow'>{talentParseResult.platform_products && talentParseResult.platform_products.join(',')}</div>
            </div>
            <div className='row-item'>
              <div className='title'>系统构筑基盘</div>
              <div className='grow'>{talentParseResult.sys_infra && talentParseResult.sys_infra.join(',')}</div>
            </div>
            <div className='row-item'>
              <div className='title'>开发框架</div>
              <div className='grow'>{talentParseResult.framework && talentParseResult.framework.join(',')}</div>
            </div>
            <div className='row-item'>
              <div className='title'>开发工具</div>
              <div className='grow'>{talentParseResult.dev_tools && talentParseResult.dev_tools.join(',')}</div>
            </div>
          </div>
        </div>
        <button onClick={()=>this.match()}>匹配计算</button>
        <div className='row-item'>匹配得分:{matchScoreResult.matchScore}</div>
        <div className='row-item'>性别得分:{JSON.stringify(matchScoreResult.scoreGender)}</div>
        <div className='row-item'>年龄得分:{JSON.stringify(matchScoreResult.scoreAge)}</div>
        <div className='row-item'>国籍得分:{JSON.stringify(matchScoreResult.scoreCountry)}</div>
        <div className='row-item'>日语水平得分:{JSON.stringify(matchScoreResult.scoreJapaneseLevel)}</div>
        <div className='row-item'>英语水平得分:{JSON.stringify(matchScoreResult.scoreEnglishLevel)}</div>
        <div className='row-item'>必备技能得分:{JSON.stringify(matchScoreResult.scoreMustSkill)}</div>
        <div className='row-item'>尚可技能得分:{JSON.stringify(matchScoreResult.scoreCanSkill)}</div>
        <div className='row-item'>作业工程得分:{JSON.stringify(matchScoreResult.scoreWorkProcess)}</div>
        <div className='row-item'>工作方式得分:{JSON.stringify(matchScoreResult.scoreWorkStyle)}</div>
        <div className='row-item'>胜任角色得分:{JSON.stringify(matchScoreResult.scoreWorkRole)}</div>
        <div className='row-item'>稼动日期得分:{JSON.stringify(matchScoreResult.scoreMoveTime)}</div>
        <div className='row-item'>要员所属得分:{JSON.stringify(matchScoreResult.scoreTalentBelong)}</div>
        <div className='row-item'>单价得分:{JSON.stringify(matchScoreResult.scorePrice)}</div>
        <div className='row-item'>开发语言得分:{JSON.stringify(matchScoreResult.scoreDevLang)}</div>
        <div className='row-item'>集成开发环境得分:{JSON.stringify(matchScoreResult.scoreIDE)}</div>
        <div className='row-item'>数据库得分:{JSON.stringify(matchScoreResult.scoreDBMS)}</div>
        <div className='row-item'>操作系统得分:{JSON.stringify(matchScoreResult.scoreOS)}</div>
        <div className='row-item'>平台产品得分:{JSON.stringify(matchScoreResult.scorePlatformProducts)}</div>
        <div className='row-item'>系统构建基盘得分:{JSON.stringify(matchScoreResult.scoreVirtualMachine)}</div>
        <div className='row-item'>IT框架得分:{JSON.stringify(matchScoreResult.scoreFramework)}</div>
        <div className='row-item'>开发工具得分:{JSON.stringify(matchScoreResult.scoreDevTools)}</div>
      </div>
    );
  }
}

export default ActivityPage;