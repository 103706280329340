import { ConfigProvider, Empty, Select, Space, Table } from "antd";
import Column from "antd/es/table/Column";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Server } from "../../server/server";
import { GoodsCurrency, PayProvider, PayWay, PointConsumeSubCategory, TaxCategory } from "../common/common";
import { utc2Local } from "../util/date";
import './TransactionPage.css';

class TransactionPage extends React.Component<WithTranslation, TransactionPageState> {
    
    constructor(props: WithTranslation) {
        super(props);
        this.state = {
            type: PointConsumeSubCategory.NONE,
            list: [],
            loading: false,
        };
    }

    componentDidMount(): void {
        this.loadTransaction(this.state.type)
    }

    render(): React.ReactNode {
        const { t } = this.props
        const orderList = this.state.list || []
        const menuItems = [
            {
                value: PointConsumeSubCategory.NONE,
                label: t('pages.TransactionPage.900384-s-0'),
            },
            {
                value: PointConsumeSubCategory.RECHARGE,
                label: t('pages.TransactionPage.900384-s-1'),
            },
            {
                value: PointConsumeSubCategory.UNLOCK_CASE,
                label: t('pages.TransactionPage.900384-s-2'),
            },
            {
                value: PointConsumeSubCategory.UNLOCK_TALENT,
                label: t('pages.TransactionPage.900384-s-3'),
            },
            {
                value: PointConsumeSubCategory.PREMIUM,
                label: t('pages.TransactionPage.900384-s-4'),
            },
            {
                value: PointConsumeSubCategory.REWARDS_INVITE,
                label: t('pages.TransactionPage.900384-s-5'),
            },
        ];
        const tableEmptyRender = () => {
            return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<>{t('pages.TransactionPage.900384-o-6')}</>}></Empty>
        }
        return (
            <div className="main">
                <Space>
                    <Select popupMatchSelectWidth={false} options={menuItems} defaultValue={-1} onChange={this.onFilterChange} />
                </Space>
                <ConfigProvider renderEmpty={tableEmptyRender}>
                    <Table 
                        rowKey={record => record.id}
                        dataSource={orderList} 
                        size="small" 
                        bordered 
                        // pagination={{ pageSize: 50 }} 
                        pagination={false}
                        // locale={{emptyText: t('pages.TransactionPage.900384-18')}}
                        scroll={{ x: 'max-content'} }>
                        <Column 
                            title={t('pages.TransactionPage.900384-t-0')} 
                            dataIndex="createTime" 
                            key="createTime" 
                            render={(_: any, record: DataType) => (
                                utc2Local(record.createTime, 'YYYY/MM/DD HH:mm')
                            )}
                        />
                        <Column 
                            title={t('pages.TransactionPage.900384-t-1')} 
                            dataIndex="subCategory" 
                            key="subCategory" 
                            render={(_: any, record: DataType) => {
                                // 分类
                                switch(record.subCategory) {
                                    case PointConsumeSubCategory.RECHARGE:
                                        return t('pages.TransactionPage.900384-s-1');
                                    case PointConsumeSubCategory.UNLOCK_CASE:
                                        return t('pages.TransactionPage.900384-s-2');
                                    case PointConsumeSubCategory.UNLOCK_TALENT:
                                        return t('pages.TransactionPage.900384-s-3');
                                    case PointConsumeSubCategory.PREMIUM:
                                        return t('pages.TransactionPage.900384-s-4');
                                    case PointConsumeSubCategory.REWARDS_INVITE:
                                        return t('pages.TransactionPage.900384-s-5');
                                    default:
                                        return '';
                                }
                            }}
                        />
                        <Column 
                            title={t('pages.TransactionPage.900384-t-2')} 
                            dataIndex="payProvider" 
                            key="payProvider" 
                            render={(_: any, record: DataType) => {
                                // 支付方式
                                const subCategory = record.subCategory
                                if (subCategory !== PointConsumeSubCategory.RECHARGE) {
                                    // 梧桐果
                                    return t('pages.TransactionPage.900384-o-1')
                                }
                                const payProvider = record.payProvider
                                switch(payProvider) {
                                    case PayProvider.PAYPAL:
                                        return t('pages.TransactionPage.900384-p-01');
                                    case PayProvider.WEPAY:
                                        return t('pages.TransactionPage.900384-p-02');
                                    case PayProvider.ALIPAY:
                                        return t('pages.TransactionPage.900384-p-03');
                                    case PayProvider.CREDIT:
                                        return t('pages.TransactionPage.900384-p-04');
                                    default:
                                        return t('pages.TransactionPage.900384-p-05');
                                }
                            }}
                        />
                        <Column 
                            title={t('pages.TransactionPage.900384-t-3')} 
                            // dataIndex="payCurrency" 
                            key="payCurrency"
                            render={(_: any, record: DataType) => {
                                // 币种
                                const subCategory = record.subCategory
                                const payWay = record.payWay
                                if (subCategory == PointConsumeSubCategory.RECHARGE && payWay == PayWay.THIRD_PARTY) {
                                    const payCurrency = record.payCurrency || GoodsCurrency.JPY
                                    let unitStr = payCurrency == GoodsCurrency.JPY ? t('pages.TransactionPage.900384-o-2'): t('pages.TransactionPage.900384-o-3')
                                    return unitStr
                                }
                                return '/'
                            }}
                        />
                        <Column 
                            title={t('pages.TransactionPage.900384-t-4')} 
                            dataIndex="goodsPrice" 
                            key="goodsPrice" 
                            render={(_: any, record: DataType) => {
                                // 商品金额
                                const subCategory = record.subCategory
                                const payWay = record.payWay
                                if (subCategory == PointConsumeSubCategory.RECHARGE && payWay == PayWay.THIRD_PARTY) {
                                    const goodsPrice = record.goodsPrice
                                    return Number(goodsPrice).toLocaleString()
                                }
                                return '/'
                            }}
                        />
                        <Column 
                            title={t('pages.TransactionPage.900384-t-5')} 
                            dataIndex="goodsTaxCategory" 
                            key="goodsTaxCategory" 
                            render={(_: any, record: DataType) => {
                                // 税种
                                const subCategory = record.subCategory
                                const payWay = record.payWay
                                if (subCategory == PointConsumeSubCategory.RECHARGE && payWay == PayWay.THIRD_PARTY) {
                                    const goodsTaxCategory = record.goodsTaxCategory
                                    if (goodsTaxCategory === TaxCategory.EXCISE) { return t('pages.TransactionPage.900384-o-4') }
                                    if (goodsTaxCategory === TaxCategory.NONE) { return '/'}
                                }
                                return '/'
                            }}
                        />
                        <Column 
                            title={t('pages.TransactionPage.900384-t-6')} 
                            dataIndex="goodsTaxCategory" 
                            key="goodsTaxCategory" 
                            render={(_: any, record: DataType) => {
                                // 税率
                                const subCategory = record.subCategory
                                const payWay = record.payWay
                                if (subCategory == PointConsumeSubCategory.RECHARGE && payWay == PayWay.THIRD_PARTY) {
                                    const goodsTaxRate = record.goodsTaxRate
                                    return Number(goodsTaxRate*100).toFixed(2)+'%'
                                }
                                return '/'
                            }}
                        />
                        <Column 
                            title={t('pages.TransactionPage.900384-t-7')} 
                            dataIndex="payTaxAmount" 
                            key="payTaxAmount"
                            render={(_: any, record: DataType) => {
                                // 税额
                                const subCategory = record.subCategory
                                const payWay = record.payWay
                                if (subCategory == PointConsumeSubCategory.RECHARGE && payWay == PayWay.THIRD_PARTY) {
                                    const payTaxAmount = record.payTaxAmount
                                    return Number(payTaxAmount).toLocaleString()
                                }
                                return '/'
                            }}
                        />
                        <Column 
                            title={t('pages.TransactionPage.900384-t-8')} 
                            dataIndex="payTotalAmount" 
                            key="payTotalAmount"
                            render={(_: any, record: DataType) => {
                                // 总金额
                                const subCategory = record.subCategory
                                const payWay = record.payWay
                                if (subCategory == PointConsumeSubCategory.RECHARGE && payWay == PayWay.THIRD_PARTY) {
                                    const payTotalAmount = record.payTotalAmount
                                    return Number(payTotalAmount).toLocaleString()
                                }
                                return '/'
                            }}
                        />
                        <Column 
                            title={t('pages.TransactionPage.900384-t-9')} 
                            dataIndex="pointChange" 
                            key="pointChange"
                            render={(_: any, record: DataType) => {
                                // 梧桐果变更
                                // const subCategory = record.category
                                // const pointChange = record.pointChange
                                const pointChange = Number(record.pointChange)
                                if (pointChange > 0) return '+' + pointChange.toLocaleString()
                                return Number(pointChange).toLocaleString()
                            }}
                        />
                        <Column 
                            title={t('pages.TransactionPage.900384-t-10')} 
                            dataIndex="inviteUserName" 
                            key="inviteUserName"
                            render={(_: any, record: DataType) => {
                                // 被邀请人
                                const inviteUserName = record.inviteUserName
                                const inviteCompanyShort = record.inviteCompanyShort
                                if (inviteUserName) {
                                    return inviteCompanyShort ?  inviteCompanyShort + ' ' + inviteUserName : inviteUserName
                                }
                                return '/'
                            }}
                        />
                        <Column
                            title={t('pages.TransactionPage.900384-t-11')}
                            key="action"
                            render={(_: any, record: DataType) => {
                                // 发票
                                const subCategory = record.subCategory
                                const payWay = record.payWay
                                if (subCategory == PointConsumeSubCategory.RECHARGE && payWay == PayWay.THIRD_PARTY) {
                                    const orderId = record.linkId.toString()
                                    const orderTimeStr = utc2Local(record.createTime, 'YYYYMMDDHHmm')
                                    let fileName = '領収書_梧桐智配サービス料_' + orderTimeStr + '.pdf'
                                    return <a onClick={() => {this.downloadInvoice(orderId, fileName)}}>{t('pages.TransactionPage.900384-o-5')}</a>
                                }
                                return ''
                            }}
                        />
                    </Table>
                </ConfigProvider>
            </div>
        );
    }

    loadTransaction = (orderType: number) => {
        Server.account.allTransaction(orderType).then((res: any) => {
            this.setState({
                list: res.data
            })
        })
    }

    onFilterChange = (value:any) => {
        // console.log('onFilterChange', value)
        this.setState({
            type: value
        })
        this.loadTransaction(value)
    }

    downloadInvoice = (orderId:string, fileName:string) => {
        Server.order.downloadInvoice(orderId, fileName)
    }
}

export default withTranslation()(TransactionPage)

interface TransactionPageState {
    /** 分类类型 */
    type: number;
    /** 数据列表 */
    list: any[];
    /** 加载标志位 */
    loading: boolean
}

interface DataType {
    /** id */
    id: React.Key;
    /** 日期时间 */
    createTime: Date;
    /** 交易分类 0收入 1支出 */
    category:number;
    /** 交易子分类 0充值（购买梧桐果）1激活解锁(案件)（梧桐果支付） 2激活解锁(要员)（梧桐果支付） 3年付费会员（梧桐果支付）4邀请注册奖励（奖励梧桐果）*/
    subCategory: number;
    /** 梧桐果交易变更 */
    pointChange: number;
    /** 支付方式 0：梧桐果支付 1：第三方在线支付 2：其他 */
    payWay: number;
    /** 第三方支付时的第三方支付平台标识 0:Paypal、1:WePay 2:支付宝 3:信用卡 4:其他 */
    payProvider: number;
    /** 支付货币 0：梧桐果 1：日元 2：人民币 */
    payCurrency: number;
    /** 商品金额 */
    goodsPrice: number;
    /** 商品税种 -1:无 0:消费税 */
    goodsTaxCategory: number;
    /** 商品税率 */
    goodsTaxRate: number;
    /** 商品税额 */
    payTaxAmount: number;
    /** 总金额（商品价值+税额）*/
    payTotalAmount: number;

    /** 被邀请人名称（姓+名） */
    inviteUserName: string;
    /** 被邀请人所属公司（简称） */
    inviteCompanyShort:string;
    /** 关联id（订单id 或邀请id） */
    linkId: string;
}